document.addEventListener('DOMContentLoaded', function () {
    // check if user opted in
    _paq.push([
        function () {
            if (!this.isUserOptedOut()) {
                // if user didn't opt out
                if (document.cookie.match(/mtm_/)) {
                    // user opted in
                    _paq.push(['trackPageView'])
                    _paq.push(['enableLinkTracking'])
                } else {
                    // user didn't opt in or opt out
                    // show GDPR footer
                    document
                        .getElementById('gdprFooter')
                        .classList.add('visible')
                    document
                        .getElementById('gdprFooter')
                        .classList.remove('invisible')
                }
            }
        },
    ])
})
