document.querySelectorAll(".blog-card").forEach(card =>{
 let element = card.querySelector(".blog-title")
 let divHeight = (card.querySelector(".blog-title").offsetHeight -20)/ 18 * 1.3
 if(divHeight >0 && divHeight <1 ){
  card.querySelector(".blog-body").style.webkitLineClamp = 5
 } else if(divHeight >=1 && divHeight <2 ){
  card.querySelector(".blog-body").style.webkitLineClamp = 4
 }else{
  card.querySelector(".blog-body").style.webkitLineClamp = 3
 }

})