const alert = document.querySelector('.email-alert-container')

document.addEventListener('DOMContentLoaded', function () {
    window.setTimeout(() => {
        if (document.querySelector('.email-alert-container')) {
            document.querySelector('.email-alert-container').style.display =
                'none'
        }
    }, 5000)
    if (document.querySelector('.close-alert')) {
        document.querySelector('.close-alert').addEventListener('click', () => {
            if (document.querySelector('.email-alert-container')) {
                document.querySelector('.email-alert-container').style.display =
                    'none'
            }
        })
    }
})
