function matomoOptIn(event) {
    _paq.push(['rememberConsentGiven'])
    matomoTrack()
    document.getElementById('gdprFooter').classList.remove('visible')
    document.getElementById('gdprFooter').classList.add('invisible')
}

function matomoOptOut(event) {
    _paq.push(['optUserOut'])
    document.getElementById('gdprFooter').classList.remove('visible')
    document.getElementById('gdprFooter').classList.add('invisible')
    //
    var xhttp = new XMLHttpRequest()
    var url =
        '//stats.tiqtime.com/matomo.php?idsite=2&rec=1&e_c=consent&e_a=optout'
    xhttp.open('POST', url)
    xhttp.send()
}
function matomoMoreInformation(event) {
    document.querySelector('.gdpr-info').classList.remove('hidden')
    document.querySelector('.decline-btn').classList.remove('hidden')
    document.querySelector('.more-btn').classList.add('hidden')
}
function matomoTrack() {
    _paq.push(['trackPageView'])
    _paq.push(['enableLinkTracking'])
}

document
    .querySelector('.more-btn')
    .addEventListener('click', matomoMoreInformation)
document.querySelector('.decline-btn').addEventListener('click', matomoOptOut)
document.querySelector('.accept-cookies').addEventListener('click', matomoOptIn)
