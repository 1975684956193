var acc = document.getElementsByClassName('accordion')

if (acc) {
    for (let i = 0; i < acc.length; i++) {
        acc[i].addEventListener('click', function () {
            this.classList.toggle('active')
            if (this.classList.contains('active')) {
                this.querySelector('.icon-right').style.display = 'none'
                this.querySelector('.icon-left').style.display = 'block'
            } else {
                this.querySelector('.icon-right').style.display = 'block'
                this.querySelector('.icon-left').style.display = 'none'
            }

            var panel = this.nextElementSibling
            if (this.classList.contains('active')) {
                panel.style.display = 'block'
            } else {
                panel.style.display = 'none'
            }
        })
    }
}
