const tabcontent = document.getElementsByClassName('tabcontent')
var tablinks = document.getElementsByClassName('tablinks')
const tabs = ['applications', 'compatible', 'partners']
if (tabcontent.length > 0 && tablinks.length > 0) {
    var windowWidth = window.innerWidth
    var displayProperty = windowWidth > 800 ? 'flex' : 'block'
    openTabs(0, tabs[0])

    function openTabs(index, elName) {
        for (let i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = 'none'
        }
        for (let i = 0; i < tablinks.length; i++) {
            if (tablinks[i].classList.contains('btn-darkerGreen')) {
                tablinks[i].classList.remove('btn-darkerGreen')
            }
            tablinks[i].classList.add('btn-ghost')
        }
        if (document.getElementById(elName)) {
            document.getElementById(elName).style.display = displayProperty
        }
        tablinks[index].classList.add('btn-darkerGreen')
        tablinks[index].classList.remove('btn-ghost')
    }

    document.querySelectorAll('.tablinks').forEach((el, i) => {
        el.addEventListener('click', () => {
            openTabs(i, tabs[i])
        })
    })
}
