/**
 * Script for removing notification bar (only shown once on load)
 */

const notificationBar = document.getElementById('tiqNotificationBar')
const notificationBarButton = document.getElementById('notificationBarButton')
const notificationTransitionClass = 'isNotificationBarHidden'
let hideNotification = sessionStorage.getItem('hideNotification')

if (notificationBar) {
    document.body.classList.add('transition-transform', 'duration-300')
    if (hideNotification == 'true') {
        notificationBar.style.display = 'none'
    } else {
        notificationBar.style.display = 'block'
    }
    if (notificationBar && notificationBarButton) {
        notificationBarButton.addEventListener('click', () => {
            document.body.classList.add(notificationTransitionClass)
            sessionStorage.setItem('hideNotification', 'true')

            window.setTimeout(() => {
                // after the transition, remove the element
                // body translation gets corrected without transitions
                notificationBar.remove()
                document.body.classList.remove(
                    'transition-transform',
                    'duration-300',
                    notificationTransitionClass
                )
            }, 300)
        })
    }

    window.addEventListener('unload', () => {
        let hideNotification = sessionStorage.getItem('hideNotification')
        //localStorage.setItem('hideNotification', false)
    })

    window.addEventListener('load', () => {
        hideNotification = sessionStorage.getItem('hideNotification')
    })
}
