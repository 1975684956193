/**
 * Open/close behavior for mobile navigation
 */
const tiqMobileNavigationButton = document.getElementById(
    'tiqMobileNavigationButton'
)
const tiqMobileNavigationContent = document.getElementById(
    'tiqMobileNavigationContent'
)
const tiqMobileNavigationDemoButton = document.getElementById(
    'tiqMobileNavigationDemoButton'
)

let initialNavigationOffsetTop = 50
const tiqNavigation = document.getElementById('tiqNavigation')
const tiqNotificationBar = document.getElementById('tiqNotificationBar')

let scrollDebounceTimeout
const isTransparent = tiqNavigation.classList.contains('tiq-navigation-shade')

window.addEventListener('scroll', (event) => {
    scrollDebounceTimeout = setTimeout(() => {
        if (tiqNavigation.offsetTop < 10) {
            tiqNavigation.classList.remove('bg-white')
            if (isTransparent) {
                tiqNavigation.classList.add('tiq-navigation-shade')
                tiqNavigation.classList.add('bg-transparent')
            }
        } else {
            // add "stuck" class if it hasn't been added already
            tiqNavigation.classList.remove('tiq-navigation-shade')
            tiqNavigation.classList.remove('bg-transparent')
            tiqNavigation.classList.add('bg-white')
            tiqNavigation.classList.add('text-darkGreen')
        }
        // cleanup
        clearTimeout(scrollDebounceTimeout)
    }, 250)
})

// add navigation-closed class on load
document.body.classList.toggle('navigation-closed')
if (tiqMobileNavigationButton) {
    tiqMobileNavigationButton.addEventListener('click', () => {
        // animate the menu icon
        tiqMobileNavigationButton.classList.toggle('hamburger-open')

        // show the mobile menu
        tiqMobileNavigationContent.classList.toggle('hidden')
        tiqMobileNavigationContent.classList.toggle('padding-mobile')

        tiqMobileNavigationContent
            .querySelector('div')
            .classList.toggle('hidden')

        document.body.classList.toggle('navigation-open')
        document.body.classList.toggle('navigation-closed')

        if (document.body.classList.contains('navigation-open')) {
            tiqNavigation.style.backgroundColor = 'white'
        } else {
            tiqNavigation.style.removeProperty('background-color')
        }

        //  window.setTimeout(() => {
        tiqMobileNavigationContent.classList.toggle('opacity-0')
        //  }, 100)
        // hide the demo button
        tiqMobileNavigationDemoButton.classList.toggle('hidden')
        // hide notification bar when the menu is toggled
        if (tiqNotificationBar) {
            tiqNotificationBar.classList.toggle('hidden')
        }
    })
}
